<template>
  <v-container class="not-extended pa-0">
    <v-row no-gutters class="justify-space-between">
      <v-col cols="12" md="5">
        <!-- DIRECTORIO DE USUARIOS -->
        <Scroller
          :loading="loading.users"
          title="Directorio de Usuarios"
          description="Selecciona un usuario para ver los canales asociados"
          :items="GetUsers"
          :keys="GetUserKeys"
          :current="pagination.current"
          :pages="pagination.pages"
          :btnLoading="loading.urls"
          @add-btn="handlerNewForm"
          @edit-btn="(newUser = false), (modal = true)"
          @select-item="handlerSelectUser"
          @page-change="handlerPageChange"
          @search="handlerSearch"
          @clear-search="handlerClearSearch"
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
        align-self="center"
        :class="{ 'my-6': isMobile, 'text-center': true }"
      >
        <v-icon x-large
          >mdi-{{
            isMobile ? "swap-vertical-bold" : "swap-horizontal-bold"
          }}</v-icon
        >
      </v-col>
      <v-col cols="12" md="5">
        <!-- INDICE DE CANALES -->
        <channel-index
          :items="GetChannels"
          :loading="loading.channels"
          :selected="selectedChannels"
          :actionSelected="selectedChannelsActions"
          :shaped="false"
          :selectable="true"
          :multiple="true"
          :dense="false"
          :disabled="!selectedUser ? true : false"
          :baseTextLength="40"
          :actions="true"
          :subheaders="true"
          :order="false"
          :search-dense="false"
          :case-sensitive-dense="false"
          @click-action="handlerSelectAction"
          @select-channel="handlerSelectChannel"
        />

        <div class="text-center mt-6">
          <v-btn
            color="primary"
            :loading="loading.button"
            @click="saveChannels"
            :disabled="selectedUser ? false : true"
            >Guardar Selección</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <base-modal
      v-model="modal"
      @close="handlerCloseModal"
      :persistent="loading.modal"
      :title="newUser ? 'Crear nuevo usuario' : 'Editar usuario existente'"
    >
      <add-user-form
        @submit="saveUser"
        @update="formUpdateUser"
        :loading="loading.modal"
        :formData="newUser ? null : selectedUser"
        :accessUrlList="GetAccessUrls"
        :keys="GetUserKeys"
        :roles_ids="roles_ids"
      />
    </base-modal>
    <confirm-modal ref="confirmDialog" />
  </v-container>
</template>

<script>
import Scroller from "@/components/Scroller/UsersScroller.vue";
import ChannelIndex from "@/components/Business/channelIndex.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserConfigPage",
  data: () => ({
    page: "10",
    loading: {
      channels: false,
      users: false,
      modal: false,
      button: false,
      urls: false,
    },
    modal: false,
    selectedChannels: [],
    selectedChannelsActions: [],
    roles_ids: [],
    roles_permisos: [],
    selectedUser: null,
    newUser: false,
    pagination: {
      current: 1,
      pages: 1,
      limit: 10,
      filter: "",
    },
  }),
  components: {
    Scroller,
    ChannelIndex,

    BaseModal: () => import("@/components/Modal/BaseModal.vue"),
    ConfirmModal: () => import("@/components/Modal/ConfirmModal.vue"),
    AddUserForm: () => import("@/components/Forms/business/addUserForm.vue"),
  },
  created() {
    this.setPage();
  },
  computed: {
    ...mapGetters("Channels", [
      "GetChannels",
      "GetAllChannelList",
      "GetAccessUrls",
    ]),
    ...mapGetters("Users", ["GetUsers", "GetUserKeys", "GetUserMessages"]),

    paginationKeys() {
      return this.$store.getters.GetPaginationKeys;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions("Channels", ["REQUEST_CHANNELS", "REQUEST_ACCESS_URLS"]),
    ...mapActions("Users", [
      "REQUEST_USERS",
      "CREATE_USER",
      "UPDATE_USER",
      "REQUEST_USERS_ROLES",
    ]),

    async setPage() {
      this.$store.commit("setPage", { page: this.page });
      this.setUsers();
      this.setChannels();
      this.setAccessUrls();
      this.setUsersRoles();
    },
    setUsers() {
      this.loading.users = true;
      const payload = {
        limit: this.pagination.limit,
        page: this.pagination.current,
        filter: this.pagination.filter,
      };
      this.REQUEST_USERS(payload)
        .then((response) => {
          // Settear valores de la paginacion luego de la consulta
          this.pagination.pages = response[this.paginationKeys.pages];
          this.pagination.current = response[this.paginationKeys.current];
          this.pagination.limit = response[this.paginationKeys.limit];
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading.users = false));
    },
    setChannels() {
      this.loading.channels = true;
      this.REQUEST_CHANNELS()
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading.channels = false));
    },
    setAccessUrls() {
      this.loading.urls = true;
      this.REQUEST_ACCESS_URLS()
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading.urls = false));
    },
    setUsersRoles() {
      const payload = {
        limit: this.pagination.limit,
        page: this.pagination.current,
      };
      this.REQUEST_USERS_ROLES(payload)
        .then((response) => {
          this.roles_ids = response;
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },
    saveUser(form) {
      this.loading.modal = true;
      this.CREATE_USER(form)
        .then(async (message) => {
          await this.setUsers();
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
          this.handlerCloseModal();
        })
        .catch((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: response.message,
            top: true,
            right: true,
            color: response.type,
          });
        })
        .finally(() => (this.loading.modal = false));
    },
    saveChannels() {
      this.$refs.confirmDialog
        .show({
          title: this.GetUserMessages.assign,
          description: this.GetUserMessages.assignDescription,
        })
        .then(async (result) => {
          if (result) {
            await this.updateUserChannels();
            this.updateUser(this.selectedUser);
          }
        });
    },
    updateUser(user) {
      this.loading.modal = true;
      this.loading.button = true;
      this.UPDATE_USER(user)
        .then(async (message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
          await this.setUsers();
          this.modal = false;
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(
          () => ((this.loading.modal = false), (this.loading.button = false))
        );
    },
    formUpdateUser(payload) {
      const user = payload.data;
      user[this.GetUserKeys.channel.channel] = payload.channels;
      user[this.GetUserKeys.id] = payload.id;

      this.updateUser(user);
    },
    updateUserChannels() {
      // Se remueven los canales que han sido deseleccionados por el usuario
      this.selectedUser[this.GetUserKeys.channel.channel] = this.selectedUser[
        this.GetUserKeys.channel.channel
      ].filter((v) => {
        return this.selectedChannels.includes(v);
      });
      // Se recorre la lista de canales seleccionados
      this.selectedChannels.forEach((channel) => {
        // Se verifica y compara los canales del usuario seleccionado con los canales seleccionados
        let existCheck = this.selectedUser[
          this.GetUserKeys.channel.channel
        ].find((e) => e[this.GetUserKeys.channel.id] === channel);
        // En el caso de que existan canales seleccionados que no tenga el usuario seleccionado, se los agrega
        let isOwner = this.selectedChannelsActions.find(
          (owc) => owc === channel
        ); // Verificamos a cuales de los canales el usuario seleccionado es responsable
        if (!existCheck) {
          let item = {};
          item[this.GetUserKeys.channel.id] = channel; // Se agrega el Id del canal al nuevo elemento
          item[this.GetUserKeys.channel.owner] = isOwner ? true : false; // Se define si el usuario es responsable del canal
          this.selectedUser[this.GetUserKeys.channel.channel].push(item); // Se agrega el nuevo elemento a la lista de canales del usuario seleccionado
        } else {
          // Si el usuario ya existe, solo se verifica el valor de responsable
          existCheck[this.GetUserKeys.channel.owner] = isOwner ? true : false;
        }
      });
    },

    // Metodos de ayuda
    handlerSelectChannel(channelId) {
      if (this.selectedUser) {
        if (Array.isArray(channelId)) {
          this.selectedChannels = channelId;
          this.selectedChannelsActions = this.selectedChannelsActions.filter(
            (v) => {
              return channelId.includes(v);
            }
          );
        } else {
          let index;
          if (
            this.selectedChannels.find((x, i) => {
              index = i;
              return x === channelId ? channelId : null;
            })
          ) {
            this.selectedChannels.splice(index, 1);
            let actionIndex = this.selectedChannelsActions.findIndex(
              (e) => e === channelId
            );
            if (actionIndex >= 0) {
              this.selectedChannelsActions.splice(actionIndex, 1);
            }
          } else {
            this.selectedChannels.push(channelId);
          }
        }
      }
    },
    handlerSelectAction(channel) {
      if (this.selectedChannelsActions.includes(channel)) {
        let actionIndex = this.selectedChannelsActions.findIndex(
          (e) => e === channel
        );
        if (actionIndex >= 0) {
          this.selectedChannelsActions.splice(actionIndex, 1);
        }
      } else {
        this.selectedChannelsActions.push(channel);
      }
    },
    handlerNewForm() {
      this.newUser = true;
      this.modal = true;
    },
    handlerSelectUser(user) {
      const keys = this.GetUserKeys;
      // Verificar si no es el mismo que el previamente seleccionado
      if (user?.[keys.id] != this.selectedUser?.[keys.id]) {
        this.selectedChannels = [];
        this.selectedChannelsActions = [];
        const userChannels = user?.[keys.channel.channel] ?? [];
        for (var i in userChannels) {
          let item = userChannels[i][keys.channel.id];
          this.selectedChannels.push(item);
          if (userChannels[i][keys.channel.owner]) {
            this.selectedChannelsActions.push(item);
          }
        }
        // Establecer la data del usuario seleccionado
        this.selectedUser = user;
      }
    },

    handlerCloseModal() {
      this.modal = false;
    },

    async handlerPageChange(newPage) {
      this.pagination.current = newPage;
      await this.setUsers();
    },

    async handlerSearch(value) {
      this.pagination.filter = value;
      this.pagination.current = 1;
      this.pagination.pages = 1;
      await this.setUsers();
    },

    async handlerClearSearch() {
      this.pagination.filter = "";
      this.pagination.current = 1;
      this.pagination.pages = 1;
      await this.setUsers();
    },
  },
};
</script>

<style></style>
