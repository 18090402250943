<template>
  <v-card tile>
    <v-overlay v-if="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card-title :class="['white--text', titleColor]">
      {{ title }}

      <v-spacer></v-spacer>

      <v-tooltip top color="white black--text">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            class="text--primary"
            fab
            small
            :loading="btnLoading"
            :disabled="btnDisabled"
            v-bind="attrs"
            v-on="on"
            @click="$emit('add-btn')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Crear nuevo usuario</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-text-field
        dense
        v-model="search"
        :append-outer-icon="'mdi-magnify-expand'"
        outlined
        clearable
        label="Buscar usuario"
        type="text"
        hide-details
        @click:clear="$emit('clear-search')"
        @click:append-outer="$emit('search', search)"
      ></v-text-field>
      <p class="mb-0 mt-3">{{ description }}</p>
    </v-card-text>

    <v-divider></v-divider>

    <v-list class="user-box py-0">
      <v-list-item-group
        color="primary"
        v-model="user"
        mandatory
        @change="$emit('select-item', $event)"
      >
        <v-list-item
          link
          v-for="item in items"
          :key="item[keys.id]"
          @click="$emit('select-item', item)"
          :value="item"
        >
          <v-list-item-avatar>
            <v-avatar color="info" size="56" class="white--text">
              {{
                `${item[keys.name].substring(0, 1)}${item[
                  keys.lastName1
                ].substring(0, 1)}`
              }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ item[keys.name] }}
              {{ item[keys.lastName1] }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              item[keys.username]
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip left color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  @click="$emit('edit-btn', item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Editar información de {{ item[keys.name] }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-card-text :class="{ 'elevation-2': $vuetify.breakpoint.smAndDown }">
      <v-pagination
        :value="current"
        circle
        :length="pages"
        :total-visible="7"
        @input="handlerClickPagination"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UsersScroller",
  props: {
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "primary",
    },
    description: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Object,
      default: null,
    },
    selected: {
      type: String,
      default: "",
    },
    current: {
      type: Number,
      default: 1,
      description: "Numero de la pagina de usuarios actual",
    },
    pages: {
      type: Number,
      default: 1,
      description: "Numero de paginas disponibles",
    },
    btnDisabled: {
      type: Boolean,
      default: false,
      description: "Deshabilitar el boton de la esquina superior derecha",
    },
    btnLoading: {
      type: Boolean,
      default: false,
      description: "Estado de cargando, boton de la esquina superior derecha",
    },
  },
  data: () => ({
    search: "",
    user: "",
  }),
  methods: {
    handlerClickPagination(num) {
      this.$emit("page-change", num);
    },
  },
};
</script>

<style></style>
